import React, {useState} from 'react';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";
//import motion
import { motion } from 'framer-motion';
//import transition
import { transition1 } from '../transitions';
//import icons
import {ImCross} from 'react-icons/im'

// Import images
import Image1 from "../img/portfolio/1.jpg"
import Image2 from "../img/portfolio/2.jpg"
import Image3 from "../img/portfolio/3.jpg"
import Image4 from "../img/portfolio/4.jpg"
import Image5 from "../img/portfolio/5.jpg"
import Image6 from "../img/portfolio/6.jpg"
import Image7 from "../img/portfolio/7.jpg"
import Image8 from "../img/portfolio/8.jpg"
import Image9 from "../img/portfolio/9.jpg"
import Image10 from "../img/portfolio/10.jpg"
import Image11 from "../img/portfolio/11.jpg"
import Image12 from "../img/portfolio/12.jpg"
import Image13 from "../img/portfolio/13.jpg"
import Image14 from "../img/portfolio/14.jpg"
import Image15 from "../img/portfolio/15.jpg"
import Image16 from "../img/portfolio/16.jpg"
import Image17 from "../img/portfolio/17.jpg"
import Image18 from "../img/portfolio/18.jpg"
import Image19 from "../img/portfolio/19.jpg"
import Image20 from "../img/portfolio/20.jpg"
import Image21 from "../img/portfolio/21.jpg"
import Image22 from "../img/portfolio/22.jpg"
import Image23 from "../img/portfolio/23.jpg"
import Image24 from "../img/portfolio/24.jpg"
import Image25 from "../img/portfolio/25.jpg"
import Image26 from "../img/portfolio/26.jpg"
import Image27 from "../img/portfolio/27.jpg"
import Image28 from "../img/portfolio/28.jpg"
import Image29 from "../img/portfolio/29.jpg"
import Image30 from "../img/portfolio/30.jpg"

const images = [
  Image1, Image2, Image3, Image4, Image5, Image6, Image7, Image8, Image9, Image10, Image11, Image12, Image13, Image14, Image15, Image16, Image17, Image18, Image19, Image20, Image21, Image22, Image23, Image24, Image25, Image26, Image27, Image28, Image30, Image29
]

const Collage = () => {
  const [data, setData] = useState({img: '', i: 0, view: true})

  const viewImage = (img, i) => {
    setData({img,i, view: false})
  }

  const imgAction = (action) => {
    let i = data.i;
    if(action === 'next-img') {
        setData({img: images[i+1], i: i+1, view: false})
    }
    if(action === 'prev-img') {
        setData({img: images[i-1], i: i-1, view: false})
    }
    if(action === 'cancel'){
        setData({img: '', i: 0, view: true});
    }
  }

  return (
    <>
    {data.img &&
      <motion.div
        initial={{ opacity: 0, y: '-80%' }} 
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: '-80%' }}
        transition={transition1}
        className='pt-36' style = {{
        width: '100%',
        height: '100vh',
        background: 'white',
        position: 'fixed',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'auto'
      }}>
        <ImCross className='m-20 my-36' onClick={() => imgAction('cancel')} style={{position:'absolute', top:'10px', right:'10px'}}></ImCross>
        <button className='btn p-5' onClick={() => imgAction('prev-img')}> &lt; </button>
        <img className='m-5' src={data.img} style={{width:'auto', maxWidth: '70%', maxHeight: '90%'}}/> 
        <button className='btn p-5' onClick={() => imgAction('next-img')}>&gt;</button>
      </motion.div>
    }

    {data.view ? 
      <motion.div 
        initial={{ opacity: 0, y: '80%' }} 
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: '80%' }}
        transition={transition1}
        className='p-24 pt-36'
      >
        <ResponsiveMasonry
          columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}}
        >
          <Masonry gutter="15px">
            {images.map((image, i) => (
              <img key={i} src={image} style={{width:"100%", display:"block", cursor:'pointer'}} alt="" onClick={()=> viewImage(image,i,false)}/>
            ))}
          </Masonry>
        </ResponsiveMasonry>
      </motion.div>:null
    }
    </>
  )
};

export default Collage;
