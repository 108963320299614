import React from 'react';
// import images
import AboutPhoto from '../img/about/AboutPhoto.jpg';
// import link
import { Link } from 'react-router-dom';
//import motion
import { motion } from 'framer-motion';
//import transition
import { transition1 } from '../transitions';


const About = () => {
  return (
  <motion.section 
    initial={{ opacity: 0, y: '-80%' }} 
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: '-80%' }}
    transition={transition1}
    className='section my-36'
  >
    <div className='container mx-auto h-full relative'>
      <div className='flex flex-col lg:flex-row h-full items-center justify-center gap-x-24 text-center lg:text-left'>
        <div className='flex-1 max-h-96 lg:max-h-max order-2 lg:order-none'>
          <img src={AboutPhoto} alt='' />
        </div>
        <motion.div
          initial={{ opacity: 0, y: '-80%' }} 
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: '-80%' }}
          transition={transition1} 
          className='flex-1 pt-36 pb-14 lg:pt-0 lg:w-auto z-10 flex flex-col justify-center items-center lg:items-start'
        >
          <h1 className='h1'>About Me</h1>
          <br />
          <p className='mb-12 max-w-sm'>
            I am a professional photographer based in Brunei and Malaysia. 
          <br />
          <br />
            I have covered events that consist of corporate events, weddings, dance performances, orchestra and many more. My photos have been featured on various publications in schools, university and also local newspapers.
          <br />
          <br />
            To capture moments that will live on as memories is a passion of mine and I will always ensure that when working with my clients.
          <br />
          <br />
            The world is a beautiful place and one of my goals in life is that I want to travel the world and share my point of view through the lens with my friends.
          </p>
          <Link to={'/portfolio'} className='btn'>View my work</Link>
        </motion.div>
      </div>
    </div>
  </motion.section>
  )
};

export default About;
