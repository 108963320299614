import React from 'react';
import Collage from '../components/Collage';

const Portfolio = () => {
  return (
    <Collage />
  )
};

export default Portfolio;
