import React from 'react';
//import icons
import {ImFacebook, ImInstagram, ImWhatsapp} from 'react-icons/im'

const Socials = () => {
  return (
  <div className='hidden xl:flex ml-24'>
    <ul className='flex gap-x-4'>
      <li>
        <a href='https://www.facebook.com/ivansphoto94' target='_blank'>
          <ImFacebook />
        </a>
      </li>
      <li>
        <a href='https://www.instagram.com/ivanliew_' target='_blank'>
          <ImInstagram />
        </a>
      </li>
      <li>
        <a href='https://wa.me/+60165598323' target='_blank'>
          <ImWhatsapp />
        </a>
      </li>
    </ul>
  </div>
  )
};

export default Socials;
