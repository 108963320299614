import React from 'react';
// App Components
import Header from './components/Header';
import AnimRoutes from './components/AnimRoutes';
// App Router
import { BrowserRouter as Router } from 'react-router-dom';
// Motion
import { motion } from 'framer-motion';

const App = () => {
  return (
    <>
    <Router>
      <Header />
      <AnimRoutes />
    </Router>
    </>
  )
  
};

export default App;
