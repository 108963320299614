import React from 'react';
// import images
import HomePhoto from '../img/home/HomePhoto.jpg';
// import link
import { Link } from 'react-router-dom';
// import motion
import { motion } from 'framer-motion';
// import transition
import { transition1 } from '../transitions';

const Home = () => {
  return (
  <motion.section 
    initial={{ opacity: 0 }} 
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={transition1}
    className='section overflow-clip'
  >
    <div className='container mx-auto'>
      {/* text and image wrapper */}
      <div className='flex flex-col justify-center'>
        <motion.div
         initial={{ opacity: 0, y:'-50%' }} 
         animate={{ opacity: 1, y: 0 }}
         exit={{ opacity: 0, y: '-50%' }}
         transition={transition1}
         className='w-full pt-36 pb-14 lg:pt-0 lg:pb-0 lg:w-auto z-10 lg:absolute flex flex-col justify-center items-center lg:items-start'
        >
          <h1 className='h1'>
            Ivan Liew
          </h1>
          <p className='text-[26px] lg:text-[36px] mb-4 lg:mb-12'>
            Photographer based in <br /> Brunei <span> 🇧🇳 </span>  & Malaysia <span> 🇲🇾 </span>
          </p>
          <Link to={'/contact'} className='btn mb-[30px]'>Contact Me</Link>
        </motion.div>
        <div className='flex justify-end max-h-96 lg:max-h-max'>
          <motion.div
            initial={{ scale: 0 }} 
            animate={{ scale: 1 }}
            exit={{ scale: 0 }}
            transition={transition1} 
            className='relative lg:-right-40'
          >
            <img src={HomePhoto} alt='' />
          </motion.div>
        </div>
      </div>
    </div>
  </motion.section>
  )
};

export default Home;
