import React, { useRef, useState } from 'react';
// import emailjs
import emailjs from '@emailjs/browser';
//import motion
import { motion } from 'framer-motion';
//import transition
import { transition1 } from '../transitions';

const Contact = () => {
  const [name,setName] = useState('');
  const [email,setEmail] = useState('');
  const [message,setMessage] = useState('');
  const [error, setError] = useState(false);
  const [view, setView] = useState(true);

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    if (name.length == 0 || email.length == 0 || message.length == 0 ) {
      setError(true)
    }

    if (name && email && message) {
      emailjs.sendForm('service_qa33lr2', 'template_4q9m4dp', form.current, 'IBaq3k_4tb_kBvNxU')
      .then((result) => {
          setView(false);
      }, (error) => {
          console.log(error.text);
      });
    } 
  };

  return (
  <motion.section
    initial={{ opacity: 0, y: '80%' }} 
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: '80%' }}
    transition={transition1}
    className='section bg-white'
  >
    {view?
      <div className='container mx-auto h-full'>
        <div className='flex flex-col lg:flex-row h-full items-center justify-start pt-36 gap-x-8 text-center lg:text-left'>
          <div>
            <h1 className='h1'>Contact Me</h1>
            <p className='mb-12'>Email: i.vanliew@hotmail.com <br/> Phone Number: +60-16-5598323</p>
            <form ref={form} onSubmit={sendEmail} className='flex flex-col gap-y-4 w-[480px]'>
              <div className='flex gap-x-5'>
                <div>
                  <input onChange={e=>setName(e.target.value)} className='outline-none border-b border-b-primary w-[230px] h-[60px] bg-gray-100 font-secondary w-full pl-3 placeholder:text-[#757879]' type='text' name="from_name" placeholder='Your Name'></input>
                  {error && name.length<=0?
                  <label className='text-red-600'>Name can't be empty</label>:""}
                </div>
                <div>
                  <input onChange={e=>setEmail(e.target.value)} className='outline-none border-b border-b-primary w-[230px] h-[60px] bg-gray-100 font-secondary w-full pl-3 placeholder:text-[#757879]' type='email' name="from_email" placeholder='Email'></input>
                  {error && email.length<=0?
                  <label className='text-red-600'>Email can't be empty</label>:""}
                </div>
              </div>
              <div>
                <textarea onChange={e=>setMessage(e.target.value)} className='outline-none border-b border-b-primary h-[100px] bg-gray-100 font-secondary w-full pl-3 placeholder:text-[#757879] p-2' type='text' name="message" placeholder='Message'></textarea>
                {error && message.length<=0?
                <label className='text-red-600'>Message can't be empty</label>:""}
              </div>
              <button type="submit" value="Send" className='btn mb-[30px] mx-auto lg:mx-0 self-start'>Send</button>
            </form>
          </div>
        </div>
      </div>:
      <motion.div 
        initial={{ opacity: 0, y: '80%' }} 
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: '80%' }}
        transition={transition1}
        className='container mx-auto h-full'>
        <div className='flex flex-col lg:flex-row h-full items-center justify-start pt-36 gap-x-8 text-center lg:text-left'>
          <h1 className='h1'>Thank you!</h1>
        </div>
      </motion.div>
    }
  </motion.section>
  )
};

export default Contact;
